export const MAKE_REQUEST = "ACTION_MAKE_REQUEST";
export const FAIL_REQUEST = "ACTION_FAIL_REQUEST";
export const GET_ACTION_LIST = "GET_ACTION_LIST";
export const SET_REQUEST_FOR_REASSIGN = "SET_REQUEST_FOR_REASSIGN";
export const UPDATE_FETCH_ACTION_LIST_STATUS =
  "UPDATE_FETCH_ACTION_LIST_STATUS";
export const SET_SINGLE_ACTION_DETAILS = "SET_SINGLE_ACTION_DETAILS";
export const SET_UPDATE_IS_COMPLETE_RESPONSE =
  "SET_UPDATE_IS_COMPLETE_RESPONSE";
export const SET_ACTION_ACTIVITIES_DETAILS = "SET_ACTION_ACTIVITIES_DETAILS";
export const UPDATE_ISSUCCESS = "ACTION_UPDATE_ISSUCCESS";
export const SET_CANCEL_ACTION_RESPONSE = "SET_CANCEL_ACTION_RESPONSE";
export const SET_ACTION_LIST_FOR_CHART = "SET_ACTION_LIST_FOR_CHART";
