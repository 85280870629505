import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ActionDropDown = (props) => {
  const employeeData = useSelector((state) => state.user);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-three-dots-vertical"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Link
            to={`/view-action-detail/${props?.action?._id}`}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Dropdown.Item href="#/action-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-eye me-2"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
              </svg>
              View Action Details
            </Dropdown.Item>
          </Link>
          {props?.action?.actionStatus !== "REASSIGNED" &&
         // employeeData?.userData?.isMeetingOrganiser === false &&
         employeeData?.userData?._id?.toString()===props?.action?.assignedUserId?.toString() &&
          employeeData?.userData?.isAdmin === false &&
          props?.action?.isComplete === false &&
          props?.action?.actionStatus !== "REQUESTFORREASSIGN" &&
          props?.action?.isCancelled === false ? (
            <Link
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  props.handleRequestForReassignModal(props?.action?._id);
                }}
              >

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise me-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                </svg>
                Request for Reassign
              </Dropdown.Item>
            </Link>

          ) : null}
          {props?.action?.actionStatus !== "REASSIGNED" &&
            (employeeData?.userData?.isMeetingOrganiser === true ||
              employeeData?.userData?.isAdmin === true) &&
            props?.action?.isComplete === false &&
            props?.action?.isCancelled === false ? (
            <Link
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  props.handleReassignModal(props?.action?._id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-arrow-repeat me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                  <path
                    fill-rule="evenodd"
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                  />
                </svg>
                Reassign
              </Dropdown.Item>
            </Link>
          ) : null}

          {props?.action?.actionStatus !== "REASSIGNED" &&
            (employeeData?.userData?.isMeetingOrganiser === true ||
              employeeData?.userData?.isAdmin === true) &&
            props?.action?.isCancelled === false &&
            props?.action?.isComplete === false &&
            props?.action?.isApproved === false ? (
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Dropdown.Divider />
              <Dropdown.Item
                href="#/action-2"
                className="colorRed"
                onClick={() => {
                  props.handleCancelActionModal();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"></path>
                </svg>
                Cancel
              </Dropdown.Item>
            </Link>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ActionDropDown;
