import React from "react";
// @ts-ignore
import { Modal } from "react-bootstrap";
import { customName } from "../../helpers/commonHelpers";
import { useSelector } from "react-redux";

const OnlyAttendeeModal = (props) => {
  const { attendees, IsOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen } =
    props;
  // @ts-ignore
  const employeeData = useSelector((state) => state.user);
  return (
    <Modal
      show={IsOnlyAttendeeModalOpen}
      onHide={() => setIsOnlyAttendeeModalOpen(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Attendees</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="attendees-popup">
          {attendees &&
            attendees
              .filter((item) => item.empId)
              .map((attendee, index) => {
                return (
                  <div className="attendee-content" key={index}>
                    <div key={index}>
                      <div className="attendee1 attendee-list sl">
                        {" "}
                        {customName(attendee.name)}
                      </div>
                    </div>

                    <div className="action-cmnt-text">
                      <p className="detail-name word-break">
                        {" "}
                        {attendee?._id?.toString() ===
                        props?.meetingData?.createdById?.toString()
                          ? attendee.name + " (Organiser)"
                          : attendee?._id?.toString() ===
                              employeeData.userData._id.toString() &&
                            employeeData.userData.isAdmin === true
                          ? attendee.name + " (Admin)"
                          : attendee.name}{" "}
                      </p>

                      <p className="name-undertext detail-date-time">
                        {attendee.email}
                        {attendee.empId ? " (" + attendee.empId + ") " : ""}
                      </p>
                      <p className="name-undertext comment-text"></p>
                    </div>
                  </div>
                );
              })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OnlyAttendeeModal;
