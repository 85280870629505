import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "../../../../node_modules/axios/index";
import { toast } from "react-toastify";

const CustomizedSwitches = ({
  data,
  organizationId,
  hostType,
  callHandleToggle,
  validateForm,
  setNewErrors,
}) => {
  const accessToken = localStorage.getItem("accessToken");
  const [isActive, setIsActive] = useState(data.isActive);

  useEffect(() => {
    if (data) {
      setIsActive(data.isActive);
    }
  }, [data.isActive]);

  const handleToggle = async (isActive) => {
    const newErrors = validateForm();

    if (Object.keys(newErrors).length !== 0 && isActive === true) {
      return setNewErrors(newErrors);
    }

    const updateStatus = { isActive, hostType };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/V1/hosting/updateHostStatus/${organizationId}`,
      updateStatus,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setIsActive(!isActive);
    if (response.data.success) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      callHandleToggle(isActive);
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        <Switch
          checked={isActive}
          onClick={() => {
            handleToggle(!isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
    </FormGroup>
  );
};

export default CustomizedSwitches;
