import React, { useState, useEffect } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingListForChart } from "../../redux/actions/meetingActions/MeetingAction";
const MeetingDetailsPieChart = ({ data, totalCount, organizationId }) => {
  const actionData = useSelector((state) => state.action);
  const meetingData = useSelector((state) => state.meeting);
  const [updatedDatas, setUpdatedDatas] = useState([]);
  const [finalMeetingDatas, setFinalMeetingDatas] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  useEffect(() => {
    // alert("innnn1")
    dispatch(getMeetingListForChart(organizationId, accessToken));
  }, []);
  useEffect(() => {
    console.log(meetingData);
    console.log(meetingData?.totalMeetingList?.scheduled);
    const updatedData = [
      {
        id: 0,
        value:
          meetingData?.totalMeetingList?.length === 0
            ? 0
            : normalize(
                meetingData?.totalMeetingList?.scheduled,
                meetingData?.totalMeetingList.totalCount
              ),
        count: meetingData?.totalMeetingList?.scheduled,
        label: "Scheduled",
        color: "#4CAF50",
      },
      {
        id: 1,

        value:
          meetingData?.totalMeetingList?.length === 0
            ? 0
            : normalize(
                meetingData?.totalMeetingList.rescheduled,
                meetingData?.totalMeetingList.totalCount
              ),
        count: meetingData?.totalMeetingList?.rescheduled,
        label: "Rescheduled",
        color: "#ffbf00",
      },
      {
        id: 2,
        value:
          meetingData?.totalMeetingList?.length === 0
            ? 0
            : normalize(
                meetingData?.totalMeetingList.closed,
                meetingData?.totalMeetingList.totalCount
              ),
        count: meetingData?.totalMeetingList?.closed,
        label: "Closed",
        color: "#0d6fdf",
      },
      {
        id: 3,
        value:
          meetingData?.totalMeetingList?.length === 0
            ? 0
            : normalize(
                meetingData?.totalMeetingList.draft,
                meetingData?.totalMeetingList.totalCount
              ),
        count: meetingData?.totalMeetingList?.draft,
        label: "Draft",
        color: "#9E9E9E",
      },

      {
        id: 4,
        // value:22%,
        count: meetingData?.totalMeetingList?.cancelled,
        value:
          meetingData?.totalMeetingList?.length === 0
            ? 0
            : normalize(
                meetingData?.totalMeetingList?.cancelled,
                meetingData?.totalMeetingList.totalCount
              ),
        label: "Cancelled",
        color: "#ff0000",
      },
      {
        id: 5,
        // value:22%,
        value:
          meetingData?.totalMeetingList?.length === 0
            ? 100
            : normalize(
                meetingData?.totalMeetingList?.totalCount === 0 ? 1 : 0,
                meetingData?.totalMeetingList?.totalCount === 0
                  ? 1
                  : meetingData?.totalMeetingList?.totalCount
              ),
        count: 0,
        label: "No Meetings",
        color: "maroon",
      },
    ];
    console.log(updatedData);
    setFinalMeetingDatas(updatedData);
  }, [meetingData?.totalMeetingList]);

  console.log(meetingData?.totalMeetingList);
  useEffect(() => {
    const updatedData = [
      {
        id: 0,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data.filter(
                  (meeting) => meeting?.meetingStatus?.status === "scheduled"
                ).length,
                data.length
              ),
        count: data.filter(
          (meeting) => meeting?.meetingStatus?.status === "scheduled"
        ).length,
        label: "Scheduled",
        color: "#4CAF50",
      },
      {
        id: 1,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data.filter(
                  (meeting) => meeting?.meetingStatus?.status === "rescheduled"
                ).length,
                data.length
              ),
        count: data.filter(
          (meeting) => meeting?.meetingStatus?.status === "rescheduled"
        ).length,
        label: "Rescheduled",
        color: "#ffbf00",
      },
      {
        id: 2,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data.filter(
                  (meeting) => meeting?.meetingStatus?.status === "closed"
                ).length,
                data.length
              ),
        count: data?.filter(
          (meeting) => meeting?.meetingStatus?.status === "closed"
        ).length,
        label: "Closed",
        color: "#0d6fdf",
      },
      {
        id: 3,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data.filter(
                  (meeting) => meeting?.meetingStatus?.status === "draft"
                ).length,
                data.length
              ),
        count: data.filter(
          (meeting) => meeting?.meetingStatus?.status === "draft"
        ).length,
        label: "Draft",
        color: "#9E9E9E",
      },
      {
        id: 4,
        value:
          data?.length === 0
            ? 0
            : normalize(
                data.filter(
                  (meeting) => meeting?.meetingStatus?.status === "cancelled"
                ).length,
                data.length
              ),
        count: data.filter(
          (meeting) => meeting?.meetingStatus?.status === "cancelled"
        ).length,
        label: "Cancelled",
        color: "#ff0000",
      },
      {
        id: 5,
        value:
          data?.length === 0
            ? 100
            : normalize(
                data.length === 0 ? 1 : 0,
                data.length === 0 ? 1 : data.length
              ),
        count: 0,
        label: "No Meeting",
        color: "maroon",
      },
    ];
    setUpdatedDatas(updatedData);
  }, [data]);

  const normalize = (v, v2) => Number.parseFloat(((v / v2) * 100).toFixed(2));
  console.log(updatedDatas);
  const finalDatas = [
    ...updatedDatas.map((v) => ({
      ...v,
      label: v.label,
      value: normalize(v.value, data.length),
    })),
  ];
  console.log(finalDatas);
  const valueFormatter = (item) => `${item.value}%`;
  const valueFormatterb = (item) => `${item.value}%`;
  const datas = {
    data: updatedDatas,
    valueFormatter,
  };
  console.log(finalMeetingDatas);
  const finalChartDatas = {
    data: finalMeetingDatas,
    valueFormatterb,
  };
  console.log(finalChartDatas);

  console.log(datas);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <h5>Total Meetings</h5>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.count} (${item.value}%)`,
                arcLabelMinAngle: 35,
                arcLabelRadius: "60%",
                ...finalChartDatas,
                cx: 180,
                cy: 165,
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontWeight: "bold",
              },
            }}
            width={565}
            height={330}
          />
        </div>

        <div className="col-sm-6">
          <h5>Searched Meetings</h5>
          <PieChart
            series={[
              {
                arcLabel: (item) => `${item.count} (${item.value}%)`,
                arcLabelMinAngle: 35,
                arcLabelRadius: "60%",
                ...datas,
                cx: 180,
                cy: 165,
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontWeight: "bold",
              },
            }}
            //   series={
            //     [
            //     {
            //       data: updatedDatas,
            //     //   highlightScope: { fade: 'global', highlight: 'item' },
            //     //   faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            //     innerRadius: 40,
            //   outerRadius: 110,
            //   paddingAngle: 3,
            //   cornerRadius: 1,
            //   startAngle: -55,
            //   endAngle: 320,
            //   cx: 160,
            //   cy: 165,
            //     },
            //   ]
            // }
            width={565}
            height={330}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetingDetailsPieChart;
