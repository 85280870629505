import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAgendaWithMinutesDetails, setMeetingDetails } from "../../redux/actions/meetingActions/MeetingAction";
import ViewMinutes from "./ViewMinutes";
import "./minutes.css";
import CreateMinutes from "./CreateMinutes";
import { logOut } from "../../redux/actions/authActions/authAction";
import ViewParentActions from "./ViewParentActions";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";

const MinutesPage = () => {
  let { meetingId } = useParams();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const meetingData = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const [openAgendaForm, setOpenAgendaForm] = useState(false);
  useEffect(() => {
    if (accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }

    return () => {
      //dispatch(setMeetingDetails(null))
    };
  }, [minuteData.isSuccess]);

  const openAgenda = () => {
    setOpenAgendaForm(!openAgendaForm);
  };

  const closeAgenda = () => {
    setOpenAgendaForm(false);
  };
console.log(meetingData?.meetingDetails)
  return (
    <>
      <Header />
      <Sidebar />
      {meetingData?.meetingDetails !== null ? (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <CreateMinutes
                openAgendaForm={openAgendaForm}
                closeAgendaForm={closeAgenda}
              />
              {meetingData?.meetingDetails?.parentMeetingId ? (
                <form className="mt-4">
                  <ViewParentActions
                    parentMeetingId={
                      meetingData?.meetingDetails?.parentMeetingId
                    }
                    childMeetingId={meetingData?.meetingDetails?._id}
                  />
                </form>
              ) : null}
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <ViewMinutes openAgendaForm={openAgenda} inWriteMinute={true} />
            </div>
          </div>
        </div>
      ) : meetingData?.meetingDetails === null &&
        !meetingData?.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="meeting-header-text">
            <h4>Write Minutes</h4>
          </div>

          <form className="mt-2 details-form details-form-right">
            <div className="row">
              
              <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
              <NoDataFound dataType={"meeting"} />
            </div>
          </form>
        </div>
      ) : (
        <div>
          <div className="meeting-header-text">
            <h4>Write Minutes</h4>
          </div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default MinutesPage;
