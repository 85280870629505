import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import GuestAttendeesModal from "./GuestAttendeesModal";
import OnlyAttendeeModal from "./OnlyAttendeeModal";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import { customName } from "../../helpers/commonHelpers";
import AttendeesDetails from "./AttendeesDetails";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { logOut } from "../../redux/actions/authActions/authAction";

const ViewMeetingDetails = () => {
  let { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen] = useState(false);

  useEffect(() => {
    document.title = "Meeting Details: MinutesVault";
    if (accessToken) {
      dispatch(getSingleMeetingDetails(id, accessToken));
    }
    return () => {
      dispatch(unSetSingleMeetingDetails);
    };
  }, []);

  const setGuestModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsGuestModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };
  const setOnlyAttendeeModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsOnlyAttendeeModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      {meetingData.singleMeetingDetails !== null ? (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div>

              <form className="mt-2 details-form details-form-right">
                {meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ? (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting ID</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.singleMeetingDetails
                                ?.parentMeetingDetails[0].meetingId
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          <label className="mb-1">Parent Meeting Title</label>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                          <p>
                            {
                              meetingData?.singleMeetingDetails
                                ?.parentMeetingDetails[0].title
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Id</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.meetingId}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Title</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.title}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Meeting Mode</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>{meetingData.singleMeetingDetails?.mode}</p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Location</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.locationDetails
                          .isMeetingRoom === true
                          ? meetingData.singleMeetingDetails?.roomDetail[0]
                              ?.title +
                            ", " +
                            meetingData.singleMeetingDetails?.roomDetail[0]
                              .location +
                            ", " +
                            meetingData?.singleMeetingDetails?.unitDetail?.name
                          : meetingData.singleMeetingDetails?.locationDetails
                              .location}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">
                        Meeting Link{" "}
                       
                      </label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      {meetingData?.singleMeetingDetails?.link &&
                      meetingData?.singleMeetingDetails?.hostDetails
                        ?.hostType === "MANUAL" ? (
                        <Link
                          target={"_blank"}
                          to={meetingData?.singleMeetingDetails?.link}
                        >
                          Click to Join Meeting
                        </Link>
                      ) : meetingData?.singleMeetingDetails?.link ? (
                        <Link
                          target={"_blank"}
                          to={
                            meetingData?.singleMeetingDetails?.createdById?.toString() ===
                            employeeData?.userData?._id?.toString()
                              ? meetingData?.singleMeetingDetails?.hostDetails
                                  ?.hostLink
                              : meetingData?.singleMeetingDetails?.link
                          }
                        >
                          Click to Join Meeting
                        </Link>
                      ) : meetingData?.singleMeetingDetails?.hostDetails
                          ?.hostType === "ZOOM" &&
                        !meetingData?.singleMeetingDetails?.link ? (
                        <p>Zoom link will be added once schedule the meeting</p>
                      ) : (
                        <p> NA</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Date & Time</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <p>
                        {meetingData.singleMeetingDetails?.date},
                        {meetingData.singleMeetingDetails?.fromTime} to{" "}
                        {meetingData.singleMeetingDetails?.toTime}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                      <label className="mb-1">Status</label>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                      <span
                        className={`badge bg-opacity-10 ${
                          meetingData.singleMeetingDetails?.meetingStatus
                            ?.status === "scheduled"
                            ? "scheduled-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "rescheduled"
                            ? "rescheduled-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "closed"
                            ? "closed-color"
                            : meetingData.singleMeetingDetails?.meetingStatus
                                ?.status === "draft"
                            ? "draft-color"
                            : "canceled-color"
                        }`}
                      >
                        {meetingData.singleMeetingDetails?.meetingStatus?.status
                          .charAt(0)
                          .toUpperCase() +
                          meetingData.singleMeetingDetails?.meetingStatus?.status.slice(
                            1
                          )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {meetingData.singleMeetingDetails?.attendees.length > 0 ? (
                    <div className="form-group mb-0">
                      {meetingData?.singleMeetingDetails?.attendees.filter(
                        (attendee) => attendee?.isEmployee === true
                      ).length > 0 && (
                        <div className="row align-items-center mb-2">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <label className="pb-1"> Attendees</label>
                          </div>

                          <div
                            className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                            onClick={() => {
                              setOnlyAttendeeModalStatus(
                                true,
                                meetingData?.singleMeetingDetails?.attendees
                              );
                            }}
                          >
                            <div className="attendees">
                              {meetingData?.singleMeetingDetails?.attendees
                                .filter(
                                  (attendee) => attendee?.isEmployee === true
                                )
                                .filter((item, index) => index < 5)
                                .map((guest) => (
                                  <div
                                    title={` ${
                                      meetingData?.singleMeetingDetails?.createdById.toString() ===
                                      guest._id.toString()
                                        ? "Organiser"
                                        : "Employee"
                                    }`}
                                    className={`attendee-list ${
                                      meetingData?.singleMeetingDetails?.createdById.toString() ===
                                      guest._id.toString()
                                        ? "attendees-border"
                                        : ""
                                    }`}
                                    key={guest._id}
                                  >
                                    {customName(guest.name)}
                                  </div>
                                ))}
                              <p className="m-0">
                                {meetingData.singleMeetingDetails?.attendees.filter(
                                  (guest) => guest.isEmployee === true
                                ).length > 5
                                  ? `+${
                                      meetingData.singleMeetingDetails?.attendees.filter(
                                        (guest) => guest.isEmployee === true
                                      ).length - 5
                                    } More`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {meetingData?.singleMeetingDetails?.attendees.filter(
                        (attendee) => attendee?.isEmployee === false
                      ).length > 0 && (
                        <div className="row align-items-center mb-2">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <label className="pb-1"> Guest(s) </label>
                          </div>
                          <div
                            className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                            onClick={() => {
                              setGuestModalStatus(
                                true,
                                meetingData?.singleMeetingDetails?.attendees
                              );
                            }}
                          >
                            <div className="attendees" title="Guest">
                              {meetingData?.singleMeetingDetails?.attendees
                                .filter(
                                  (attendee) => attendee?.isEmployee === false
                                )
                                .filter((item, index) => index < 5)
                                .map((guest) => (
                                  <div
                                    className="attendee-list"
                                    key={guest._id}
                                  >
                                    {customName(guest.name)}
                                  </div>
                                ))}
                              <p className="m-0">
                                {meetingData.singleMeetingDetails?.attendees.filter(
                                  (guest) => guest.isEmployee === false
                                ).length > 5
                                  ? `+${
                                      meetingData.singleMeetingDetails?.attendees.filter(
                                        (guest) => guest.isEmployee === false
                                      ).length - 5
                                    } More`
                                  : null}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                {meetingData.singleMeetingDetails?.agendasDetail.length !==
                0 ? (
                  <>
                    <div className="minutes-border"></div>
                    <div className="form-group mt-4 agenda">
                      <label className="mb-2">
                        <h4>Agendas</h4>
                      </label>
                      {meetingData.singleMeetingDetails?.agendasDetail
                        .length !== 0 &&
                        meetingData.singleMeetingDetails?.agendasDetail.map(
                          (agenda, index) => {
                            return (
                              <div className="mt-2 agenda-box-border">
                                <label className="mt-1 p-3 topic-head">
                                  {" "}
                                  Agenda {index + 1}
                                </label>

                                <div className="p-3">
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Agenda Title
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.title}</p>
                                      </div>
                                    </div>
                                  </div>
                                  {agenda.topic !== "" ? (
                                    <div className="form-group">
                                      <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                          <label className="mt-2 topic">
                                            Topic to Discuss
                                          </label>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                          <p className="mb-2">
                                            {agenda.topic}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className=" form-group">
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                        <label className="mt-1 mb-1">
                                          Timeline
                                        </label>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <p> {agenda.timeLine} Mins</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Agenda Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"agenda"} />
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text timeline-div">
                <h4></h4>
                {meetingData?.singleMeetingDetails?.meetingStatus?.status ===
                  "closed" &&
                (meetingData?.singleMeetingDetails?.parentMeetingDetails
                  ?.length !== 0 ||
                  meetingData?.singleMeetingDetails?.parentMeetingId) ? (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-calendar4-week  ms-2 me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                      <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </Link>
                ) : (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to={`/create-followon/${id}`}
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      visibility: "hidden",
                    }}
                  >
                    <p> View Timeline</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="currentColor"
                      className="bi bi-pencil-square me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </Link>
                )}

                {(meetingData.singleMeetingDetails?.meetingStatus?.status ===
                  "scheduled" &&
                  (employeeData?.userData?.isMeetingOrganiser === true ||
                    employeeData?.userData?.isAdmin === true) &&
                  meetingData.singleMeetingDetails?.actionDetail?.length ==
                    0) ||
                (meetingData.singleMeetingDetails?.meetingStatus?.status ===
                  "rescheduled" &&
                  (employeeData?.userData?.isMeetingOrganiser === true ||
                    employeeData?.userData?.isAdmin === true) &&
                  meetingData.singleMeetingDetails?.actionDetail?.length ===
                    0) ||
                (meetingData.singleMeetingDetails?.meetingStatus?.status ===
                  "draft" &&
                  (employeeData?.userData?.isMeetingOrganiser === true ||
                    employeeData?.userData?.isAdmin === true) &&
                  meetingData.singleMeetingDetails?.actionDetail?.length ===
                    0) ? (
                  <Link
                    className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                    id="open-form-btn"
                    to="/edit-meeting"
                    state={{
                      meetingId: id,
                      isMeetingDataUpdate: true,
                      step: meetingData,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <p> Edit</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="currentColor"
                      className="bi bi-pencil-square me-0 filter-svg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                      />
                    </svg>
                  </Link>
                ) : null}
              </div>
              <form className="mt-2 details-form details-form-right">
                {meetingData.singleMeetingDetails?.attendees.length > 0 ? (
                  <div className="form-group mb-2">
                    <AttendeesDetails
                      attendeesData={meetingData.singleMeetingDetails}
                    />
                  </div>
                ) : (
                  <>
                    <Alert
                      status={"info"}
                      message={"No Attendee Added"}
                      timeoutSeconds={0}
                    />
                    <div className="mt-3 agenda-box-border no-data-img">
                      <NoDataFound dataType={"attendee"} />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      ) : meetingData.singleMeetingDetails === null &&
        !meetingData.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <div className="meeting-header-text">
              <h4>Meeting Details</h4>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <form className="mt-2 details-form details-form-right">
                <div className="row">
                  <Alert
                    status={"info"}
                    message={"No data available."}
                    timeoutSeconds={0}
                  />
                  <NoDataFound dataType={"meeting"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
              <div className="meeting-header-text">
                <h4>Meeting Details</h4>
              </div>

              <form className="mt-2 details-form details-form-right">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loader />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}{" "}
      <GuestAttendeesModal
        IsGuestModalOpen={isGuestModalOpen}
        attendees={attendeesData}
        setIsGuestModalOpen={setIsGuestModalOpen}
      />
      <OnlyAttendeeModal
        IsOnlyAttendeeModalOpen={isOnlyAttendeeModalOpen}
        attendees={attendeesData}
        setIsOnlyAttendeeModalOpen={setIsOnlyAttendeeModalOpen}
        meetingData={meetingData}
      />
    </>
  );
};

export default ViewMeetingDetails;
