export const MAKE_REQUEST='MAKE_REQUEST'
export const FAIL_REQUEST='FAIL_REQUEST'
export const OTP_SENT='OTP_SENT'
export const UPDATE_ISSUCCESS='UPDATE_ISSUCCESS'
export const OTP_VERIFIED='OTP_VERIFIED'
export const UPDATE_OTP_PROCESSED='UPDATE_OTP_PROCESSED'
export const PROCESSS_LOGOUT="PROCESSS_LOGOUT"
export const OTP_RESENT="OTP_RESENT"
export const SET_PASSWORD="SET_PASSWORD"
export const LOGIN_PROCESS="LOGIN_PROCESS"
export const OTP_SENT_FOR_LOGIN_BY_OTP="OTP_SENT_FOR_LOGIN_BY_OTP"
export const UPDATE_TIMER="UPDATE_TIMER"
export const SET_INVALID_USER="SET_INVALID_USER"
export const LOGIN_PROCESS_FOR_GMAIL="LOGIN_PROCESS_FOR_GMAIL"

