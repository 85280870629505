import React, { useState, useEffect } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getActionListForChart } from "../../redux/actions/actionAction/ActionAction";
const ActionDetailsPieChart = ({ data, totalCount, organizationId }) => {
  const actionData = useSelector((state) => state.action);
  const [updatedDatas, setUpdatedDatas] = useState([]);
  const [finalActionDatas, setFinalActionDatas] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  useEffect(() => {
    // alert("innnn1")
    dispatch(getActionListForChart(organizationId, accessToken));
  }, []);
  useEffect(() => {
    // alert("innnn2")
    //  dispatch(getActionListForChart(organizationId, accessToken));
    const updatedData = [
      {
        id: 0,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.pending,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.pending,
        label: "Pending",
        color: "#FFEB3B ",
      },
      {
        id: 1,

        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.requestedForReassigned,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.requestedForReassigned,
        label: "Request For Reassign",
        color: "#8A2BE2",
      },
      {
        id: 2,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.reassigned,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.reassigned,
        label: "Reassigned",
        color: "#00CED1",
      },
      {
        id: 3,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.completed,
                actionData?.totalActionList?.totalCount
              ),
        count: actionData?.totalActionList?.completed,
        label: "Completed",
        color: "#2E8B57",
      },
      {
        id: 4,
        count: actionData?.totalActionList?.reopened,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.reopened,
                actionData?.totalActionList?.totalCount
              ),
        label: "Reopened",
        color: "#ffbf00",
      },
      {
        id: 5,
        count: actionData?.totalActionList?.approved,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.approved,
                actionData?.totalActionList?.totalCount
              ),
        label: "Approved",
        color: "#32CD32",
      },
      {
        id: 6,
        // value:22%,
        count: actionData?.totalActionList?.cancelled,
        value:
          actionData?.totalActionList?.length === 0
            ? 0
            : normalize(
                actionData?.totalActionList?.cancelled,
                actionData?.totalActionList?.totalCount
              ),
        label: "Cancelled",
        color: "#ff0000",
      },
      {
        id: 7,
        // value:22%,
        value:
          actionData?.totalActionList?.length === 0
            ? 100
            : normalize(
                actionData?.totalActionList?.totalCount === 0 ? 1 : 0,
                actionData?.totalActionList?.totalCount === 0
                  ? 1
                  : actionData?.totalActionList?.totalCount
              ),
        count: 0,
        label: "No Actions",
        color: "maroon",
      },
    ];
    console.log(actionData?.totalActionList);
    console.log(
      actionData?.totalActionList?.cancelled,
      actionData?.totalActionList?.totalCount
    );
    console.log(updatedData);
    setFinalActionDatas(updatedData);
  }, [actionData?.totalActionList]);

  console.log(actionData?.totalActionList);
  console.log(data);
  console.log(finalActionDatas);
  useEffect(() => {
    const updatedData = [
      {
        id: 0,
        value: normalize(
          data.filter((action) => action?.actionStatus === "PENDING").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "PENDING")
          .length,
        label: "Pending",
        color: "#FFEB3B ",
      },
      {
        id: 1,
        value: normalize(
          data.filter((action) => action?.actionStatus === "REQUESTFORREASSIGN")
            .length,
          data.length
        ),
        count: data.filter(
          (action) => action?.actionStatus === "REQUESTFORREASSIGN"
        ).length,
        label: "Request For Reassign",
        color: "#8A2BE2",
      },
      {
        id: 2,
        value: normalize(
          data.filter((action) => action?.actionStatus === "REASSIGNED").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "REASSIGNED")
          .length,
        label: "Reassigned",
        color: "#00CED1",
      },
      {
        id: 3,
        value: normalize(
          data.filter((action) => action?.actionStatus === "COMPLETED").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "COMPLETED")
          .length,
        label: "Completed",
        color: "#2E8B57",
      },
      {
        id: 4,
        value: normalize(
          data.filter((action) => action?.actionStatus === "REOPENED").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "REOPENED")
          .length,
        label: "Reopened",
        color: "#ffbf00",
      },
      {
        id: 5,
        value: normalize(
          data.filter((action) => action?.actionStatus === "APPROVED").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "APPROVED")
          .length,
        label: "Approved",
        color: "#32CD32",
      },
      {
        id: 6,
        // value:22%,
        value: normalize(
          data.filter((action) => action?.actionStatus === "CANCELLED").length,
          data.length
        ),
        count: data.filter((action) => action?.actionStatus === "CANCELLED")
          .length,
        label: "Cancelled",
        color: "#ff0000",
      },
      {
        id: 7,
        // value:22%,
        value: normalize(
          data.length === 0 ? 1 : 0,
          data.length === 0 ? 1 : data.length
        ),
        count: 0,
        label: "No Actions",
        color: "maroon",
      },
    ];
    console.log(updatedData);
    setUpdatedDatas(updatedData);
  }, [data]);

  const normalize = (v, v2) => Number.parseFloat(((v / v2) * 100).toFixed(2));
  console.log(updatedDatas);
  // const finalDatas = [
  //   ...updatedDatas.map((v) => ({
  //     ...v,
  //     label: v.label,
  //     value:
  //       v.id === 7
  //         ? normalize(v.value, data.length === 0 ? 1 : data.length)
  //         : normalize(v.value, data.length),
  //   })),
  // ];
  console.log(updatedDatas);
  const valueFormatter = (item) => `${item.value}%`;
  const valueFormatterb = (item) => `${item.value}%`;
  const datas = {
    data: updatedDatas,
    valueFormatter,
  };
  console.log(finalActionDatas);
  const finalActionDataForShow = [
    ...finalActionDatas.map((v) => ({
      ...v,
      label: v.label,
      value: normalize(v.value, actionData?.totalActionList),
    })),
  ];
  const finalChartDatas = {
    data: finalActionDatas,
    valueFormatterb,
  };
  console.log(finalChartDatas);
  console.log(datas);
  return (
    <div className="container">
      <div className="row">
       
          <div className="col-sm-6">
            <h5>Total Actions</h5>
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.count} (${item.value}%)`,
                  arcLabelMinAngle: 35,
                  arcLabelRadius: "60%",
                  ...finalChartDatas,
                  cx: 180,
                  cy: 165,
                  highlightScope: { fade: "global", highlight: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fontWeight: "bold",
                },
              }}
              width={565}
              height={330}
            />
          </div>
     
      
          <div className="col-sm-6">
            <h5>Searched Actions</h5>
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.count} (${item.value}%)`,
                  arcLabelMinAngle: 35,
                  arcLabelRadius: "60%",
                  ...datas,
                  cx: 180,
                  cy: 165,
                  highlightScope: { fade: "global", highlight: "item" },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fontWeight: "bold",
                },
              }}
              width={565}
              height={330}
            />
          </div>
      
      </div>
    </div>
  );
};

export default ActionDetailsPieChart;
