import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  updateIShowUpdateNotify,
  updateMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import { logOut } from "../../redux/actions/authActions/authAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";

const AddAgendas = () => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const meetingData = useSelector((state) => state.meeting);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const [numAgenda, setNumAgenda] = useState(1);
  const location = useLocation();
  const stateData = location.state;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: " ",
    topic: "",
    timeLine: "0",
    index: 0,
  });
  const [agendaData, setAgendaData] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.title = "Create Meeting: MinutesVault";
    if (stateData.isMeetingDataUpdate || meetingData.isUpdateStep) {
      document.title = "Update Meeting: MinutesVault";

      if (agendaData?.length === 0) {
        const meetingAgenda =
          meetingData?.singleMeetingDetails?.agendasDetail?.map((item) => {
            item.uid = Math.floor(100000 + Math.random() * 900000);
            return item;
          });

        setAgendaData(meetingAgenda);
      }
    }
  }, [meetingData]);

  const submitAgendasDetails = (e) => {
    e.preventDefault();

    if (agendaData?.length === 0) {
      const newErrors = validateForm(formData);
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        toast.warning("Please add agenda.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      if (meetingData?.singleMeetingDetails?.attendees?.length !== 0) {
        const newAgendaData = agendaData.map((item) => {
          return {
            topic: item.topic,
            title: item.title,
            timeLine: item.timeLine.toString(),
          };
        });
        const meetingId = meetingData?.singleMeetingDetails?._id;
        const payload = {
          sendNotification: true,
          agendas: newAgendaData,
          organizationId: userData.organizationId,
          step: 3,
        //  meetingStatus: "scheduled",
          meetingStatus: meetingData?.singleMeetingDetails?.meetingStatus?.status==="draft"?"scheduled": meetingData?.singleMeetingDetails?.meetingStatus?.status,
          isUpdate:
            stateData.isMeetingDataUpdate &&
            meetingData.singleMeetingDetails.step === 3
              ? true
              : false,
        };

        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "addAgenda",
            stateData.isMeetingDataUpdate,
            false
          )
        );
      } else {
        toast.warning("Please add attendees.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const onAddAgenda = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const uid = Math.floor(100000 + Math.random() * 900000);
      const checkDuplicateTitle = agendaData?.find(
        (agenda) => agenda?.title.trim() === formData?.title.trim()
      );

      if (!checkDuplicateTitle) {
        setAgendaData([
          ...agendaData,
          {
            topic: formData.topic,
            timeLine: formData.timeLine,
            title: formData.title,
            uid,
          },
        ]);
        setFormData({
          ...formData,
          title: " ",
          topic: "",
          timeLine: "0",
          index: 0,
        });

        setNumAgenda(numAgenda + 1);
        toast.info("One agenda added.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateIShowUpdateNotify(true));
      } else {
        toast.error("Duplicate title found.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }
    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    return errors;
  };

  const onRemoveAgenda = (uid) => {
    const filteredAgenda = agendaData.filter((item) => item.uid !== uid);
    setAgendaData(filteredAgenda);
  };

  const handleChange = (e, uid) => {
    setErrors({});
    const { name, value } = e.target;

    if (uid) {
      const modifiedAgendas = agendaData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, [name]: value };
        }
        return obj;
      });

      setAgendaData(modifiedAgendas);
    } else {
      if (name === "topic") {
        if (value.length <= textBoxStrLength) {
          setFormData({
            ...formData,
            [name]: value,
          });
        }
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const [open, setOpen] = useState(true);
  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }

    setErrors(errors);
  };
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  return (
    <form className="mt-2 no-padding-2" onSubmit={submitAgendasDetails}>
      <div className="inner-detail-form">
        <div className="form-group agenda">
          <label className="mb-1">Agenda Item</label>
        </div>
        <div>
          <div id="inputFields">
            <div id="children-pane">
              <div className="agenda-background">
                <h2>
                  <button
                    className=""
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    type="button"
                  >
                    Agenda Information
                  </button>
                  <button type="button"></button>
                </h2>
                <div className="open-div">
                  <Collapse in={open}>
                    <div>
                      <div className="form-group">
                        <div className="mb-2">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mb-1">Agenda Title </label>

                              <span className="star-mark">*</span>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                placeholder="Enter agenda title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                onBlur={validateAgendaTitle}
                                autoComplete="off"
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault(); // Prevent the Enter key behavior
                                  }
                                }}
                              />
                              {errors.title && (
                                <span className="error-message">
                                  {errors.title}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mb-1">
                              What are the topic to discuss ?
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              name="topic"
                              value={formData.topic}
                              onChange={handleChange}
                              autoComplete="off"
                              placeholder="Enter issue to discuss..."
                              id=""
                              cols="56"
                              rows="4"
                            ></textarea>
                            <p className="success-message">
                              {checkCharacterLeft(
                                formData.topic ? formData.topic : ""
                              )}{" "}
                              Characters left
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="form-group m-0">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mb-1">
                              How long will this agenda item take to discuss?
                            </label>
                          </div>
                          <div className="col-md-8">
                            <div className="time-taken">
                              <input
                                name="timeLine"
                                value={formData.timeLine}
                                onChange={handleChange}
                                onBlur={validateAgendaTime}
                                onKeyDown={handleKeyDown}
                                required="required"
                                type="number"
                                autoComplete="off"
                              />
                              <div className="minute_box">mins</div>
                            </div>
                            {errors.timeLine && (
                              <span className="error-message">
                                {errors.timeLine}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="mt-3 mb-0 plus pointer">
                          <button
                            type="button"
                            className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
                            onClick={onAddAgenda}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#fff"
                              className="bi bi-plus-circle pointer me-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                            <p> Add Agenda</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="form-group agenda">
                {" "}
                {agendaData?.length === 0 ? (
                  ""
                ) : agendaData?.length === 1 ? (
                  <div className="separate-pattern">
                    <label>
                      {" "}
                      <p>Added Agenda</p>
                    </label>
                  </div>
                ) : (
                  <div className="separate-pattern">
                    <label>
                      {" "}
                      <p>Added Agenda</p>
                    </label>
                  </div>
                )}
              </div>

              {agendaData?.length !== 0
                ? agendaData?.map((agenda, index) => {
                    return (
                      <>
                        <div className="agenda-background">
                          <h2>
                            <button
                              className=""
                              onClick={() => setOpen(!open)}
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              type="button"
                            >
                              Agenda {index + 1}
                            </button>
                            <button
                              type="button"
                              onClick={() => onRemoveAgenda(agenda.uid)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill="#0564f3"
                                className="bi-minus-circle bi bi-dash-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                              </svg>
                            </button>
                          </h2>
                          <div className="open-div">
                            <Collapse in={open}>
                              <div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="mb-1">
                                        Agenda Title
                                      </label>
                                    </div>
                                    <div className="col-md-8">
                                      <input
                                        type="text"
                                        placeholder="Enter agenda title here"
                                        name="title"
                                        value={agenda.title}
                                        readonly={true}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="mb-1">
                                        What are the topic to discuss ?
                                      </label>
                                    </div>
                                    <div className="col-md-8">
                                      <textarea
                                        name="topic"
                                        value={agenda.topic}
                                        readonly={true}
                                        autoComplete="off"
                                        placeholder="Enter issue to discuss..."
                                        id=""
                                        cols="56"
                                        rows="4"
                                      ></textarea>
                                      <p className="success-message">
                                        {checkCharacterLeft(agenda.topic)}{" "}
                                        Characters left
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group m-0">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label className="mb-1">
                                        How long will this agenda item take to
                                        discuss?
                                      </label>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="time-taken">
                                        <input
                                          name="timeLine"
                                          value={agenda.timeLine}
                                          readonly={true}
                                          onKeyDown={handleKeyDown}
                                          required="required"
                                          type="number"
                                          autocomplete="off"
                                        />
                                        <div className="minute_box">mins</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
          {errors.addAgenda ? (
            <span className="error-message">{errors.addAgenda}</span>
          ) : null}
          <div className="button-outer" style={{ marginTop: 20 }}>
            <button
              className="add-btn Mom-btn"
              onClick={(e) => dispatch(updateStep(1, true))}
            >
              <p>Back</p>
            </button>
            {!meetingData.loading ? (
              <>
                {stateData.isMeetingDataUpdate ? (
                  <button
                    className="create-meeting-button Mom-btn"
                    type="submit"
                    disabled={
                      meetingData?.isShowUpdateNotify === false ? true : false
                    }
                  >
                    <p>Update & Notify </p>
                  </button>
                ) : (
                  <>
                    <button
                      className="create-meeting-button Mom-btn"
                      type="submit"
                    >
                      <p>Save & Notify </p>
                    </button>
                  </>
                )}
              </>
            ) : (
              <LoaderButton />
            )}
          </div>
          <div></div>
        </div>
      </div>
    </form>
  );
};

export default AddAgendas;
