import React, { useState, useEffect } from "react";
import RequestForReassignModal from "./RequestForReassignModal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import {
  processRequestForReassign,
  updateIsComplete,
} from "../../redux/actions/actionAction/ActionAction";
import { formatDateTimeFormat } from "../../helpers/commonHelpers";
import { logOut } from "../../redux/actions/authActions/authAction";
import IsCompleteModal from "./IsCompleteModal";
import { Height } from "../../../node_modules/@mui/icons-material/index";
const ViewActionDetails = (props) => {
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [isRequestForReassignModalOpen, setIsRequestForReassignModalOpen] =
    useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [actionId, setActionId] = useState(null);

  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  useEffect(() => {
    document.title = "View Action Details: MinutesVault";

    if (actionData.isSuccess) {
      setIsCompleteModalOpen(false);
      setIsRequestForReassignModalOpen(false);
    }
    return () => {};
  }, [
    actionData?.singleActionDetails?.isComplete,
    actionData.isSuccess,
    actionData.isFetchedActionList,
  ]);

  const handleRequestForReassign = (reason) => {
    dispatch(
      processRequestForReassign(
        actionId,
        { requestDetails: reason },
        accessToken
      )
    );
    setActionId(null);
    setIsRequestForReassignModalOpen(false);
  };

  const handleUpdateIsComplete = (comment) => {
    dispatch(
      updateIsComplete(
        props.actionId,
        { isComplete: !actionData?.singleActionDetails?.isComplete, comment },
        accessToken
      )
    );
    setActionId(null);
  };
  const handleRequestForReassignModal = (actionId) => {
    setActionId(actionId);
    setIsRequestForReassignModalOpen(true);
  };

  const checkHandler = (actionId) => {
    setActionId(actionId);
    setIsCompleteModalOpen(true);
  };

  const isUserFind =
    actionData?.singleActionDetails?.reassigneRequestDetails?.filter(
      (user) =>
        user?.userId?.toString() === employeeData?.userData?._id?.toString()
    );

  return (
    <>
      <div className="mt-3 mb-3">
        <div className="left-detailbox">
          <h4 className="Detailsheading">Details</h4>

          <div className="mt-3 form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Meeting Title</label>
              </div>
              <div className="col-md-8">
                <Link
                  target={"_blank"}
                  to={`/view-meeting-details/${actionData?.singleActionDetails?.meetingDetail?._id}`}
                  // style={{
                  //   textDecoration: "none",
                  //   color: "black",
                  // }}
                >
                  {/* <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">Go to Meeting Details</Tooltip>
                    }
                  > */}
                  <p
                    // className="underline"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Go to Meeting Details"
                  >
                    {actionData?.singleActionDetails?.meetingDetail?.title
                      .charAt(0)
                      .toUpperCase() +
                      actionData?.singleActionDetails?.meetingDetail?.title
                        .slice(1)
                        .toLowerCase()}
                  </p>
                  {/* </OverlayTrigger> */}
                </Link>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Held On</label>
              </div>
              <div className="col-md-4">
                <p>{formatDateTimeFormat(actionData?.singleActionDetails?.meetingDetail?.date).formattedDate}</p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action Description</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.description
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.description
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Agenda</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.agendaDetail?.title
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.agendaDetail?.title
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action Due Date</label>
              </div>
              <div className="col-md-8">
                <p>{actionData?.singleActionDetails?.mainDueDate}</p>
              </div>
            </div>
          </div>

         

<div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action Completed Date</label>
              </div>
              <div className="col-md-8">
              


                <p>


                {actionData?.singleActionDetails?.isComplete
                            ? formatDateTimeFormat(
                             
                           actionData?.singleActionDetails?.isCompleteDetails[
                              actionData?.singleActionDetails?.isCompleteDetails?.length - 1
                              ].dateTime ).formattedDate
                            : "NA"}
                </p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Priority</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.priority
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.priority
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Assignee</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.assignedUserDetail?.name}
                </p>
              </div>
            </div>
          </div>

          {actionData?.singleActionDetails?.parentMinuteId ? (
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label className="mb-1">Assigned Due Date</label>
                </div>
                <div className="col-md-8">
                  <p>{actionData?.singleActionDetails?.dueDate}</p>
                </div>
              </div>
            </div>
          ) : null}

          {actionData?.singleActionDetails?.actionStatus ===
          "CANCELLED" ? null : (
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "100%" }}
                  >
                    <label className="mb-1">Delay Status</label>
                  </div>
                </div>
                <div className="col-md-8">
                  {actionData?.singleActionDetails?.isDelayed === true ? (
                    <>
                      <span className="badge  delay-color bg-opacity-10 ">
                        Delayed
                      </span>
                      <p className="detail-date-time">
                        {actionData?.singleActionDetails?.completionStatus}
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="badge notDelay-color bg-opacity-10 ">
                        Not Delayed
                      </span>
                      <p className="detail-date-time">
                        {actionData?.singleActionDetails?.completionStatus}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Status</label>
              </div>
              <div className="col-md-8">
                {actionData?.singleActionDetails?.actionStatus ===
                "COMPLETED" ? (
                  <span className="badge completed-color bg-opacity-10 ">
                    Completed
                  </span>
                ) : null}
                {actionData?.singleActionDetails?.actionStatus ===
                "REOPENED" ? (
                  <span className="badge reopened-color bg-opacity-10 ">
                    Reopened
                  </span>
                ) : null}
                {actionData?.singleActionDetails?.actionStatus ===
                "APPROVED" ? (
                  <span className="badge approved-color bg-opacity-10 ">
                    Approved
                  </span>
                ) : null}

                {employeeData?.userData?.isAdmin === false &&
                employeeData?.userData?.isMeetingOrganiser === false &&
                actionData?.singleActionDetails?.actionStatus ===
                  "REQUESTFORREASSIGN" &&
                actionData?.singleActionDetails?.reassigneRequestDetails[
                  actionData?.singleActionDetails?.reassigneRequestDetails
                    ?.length - 1
                ]?.userId?.toString() ===
                  employeeData?.userData?._id?.toString() ? (
                  <span className="badge requestforReassign-color bg-opacity-10 ">
                    Reassignment Requested
                  </span>
                ) : null}
                {employeeData?.userData?.isAdmin === false &&
                employeeData?.userData?.isMeetingOrganiser === false &&
                actionData?.singleActionDetails?.actionStatus === "PENDING" ? (
                  <span className="badge pending-color bg-opacity-10 ">
                    Pending
                  </span>
                ) : null}
                {(employeeData?.userData?.isAdmin ||
                  employeeData?.userData?.isMeetingOrganiser) &&
                actionData?.singleActionDetails?.actionStatus === "PENDING" ? (
                  <span className="badge pending-color bg-opacity-10 ">
                    Pending
                  </span>
                ) : null}
                {(employeeData.userData.isAdmin ||
                  employeeData.userData.isMeetingOrganiser) &&
                actionData?.singleActionDetails?.actionStatus ===
                  "REQUESTFORREASSIGN" ? (
                  <span className="badge requestforReassign-color bg-opacity-10 ">
                    Reassignment Requested
                  </span>
                ) : null}

                {(employeeData.userData.isAdmin ||
                  employeeData.userData.isMeetingOrganiser) &&
                actionData?.singleActionDetails?.actionStatus ===
                  "REASSIGNED" ? (
                  <span className="badge reassigned-color bg-opacity-10 ">
                    Reassigned
                  </span>
                ) : null}

                {!employeeData.userData.isAdmin &&
                !employeeData.userData.isMeetingOrganiser &&
                actionData?.singleActionDetails?.actionStatus ===
                  "REASSIGNED" ? (
                  <span className="badge reassigned-color bg-opacity-10 ">
                    Reassigned
                  </span>
                ) : null}

                {/* {(employeeData.userData.isAdmin ||
                  employeeData.userData.isMeetingOrganiser) &&
                actionData?.singleActionDetails?.actionStatus ===
                  "CANCELLED" ? (
                  <span className="badge canceled-color bg-opacity-10 ">
                    Cancelled
                  </span>
                ) : null} */}

                {actionData?.singleActionDetails?.actionStatus ===
                "CANCELLED" ? (
                  <span className="badge canceled-color bg-opacity-10 ">
                    Cancelled
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <>
            {actionData?.singleActionDetails?.actionStatus !== "REASSIGNED" &&
            (actionData?.singleActionDetails?.reassigneRequestDetails
              ?.length === 0 ||
              (actionData?.singleActionDetails?.reassigneRequestDetails[
                actionData?.singleActionDetails?.reassigneRequestDetails
                  .length - 1
              ]?.isAccepted === false &&
                actionData?.singleActionDetails?.reassigneRequestDetails[
                  actionData?.singleActionDetails?.reassigneRequestDetails
                    .length - 1
                ]?.isRejected === true)) &&
            //&& actionData?.singleActionDetails?.actionStatus !== "REASSIGNED") &&
            //  actionData?.singleActionDetails?.actionStatus === "REASSIGNED" &&
            actionData?.singleActionDetails?.isComplete === false &&
            actionData?.singleActionDetails?.isCancelled === false &&
            actionData?.singleActionDetails?.assignedUserId.toString() ===
              employeeData?.userData?._id?.toString() ? (
              <div className="complete-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="isComplete"
                  onChange={() => checkHandler(props.actionId)}
                  checked={actionData?.singleActionDetails?.isComplete}
                />
                <label>Mark as completed</label>
              </div>
            ) : null}
            {actionData?.singleActionDetails?.actionStatus !== "REASSIGNED" &&
            (isUserFind?.length === 0 ||
              (isUserFind[isUserFind?.length - 1].isAccepted === false &&
                isUserFind[isUserFind?.length - 1].isRejected === true)) &&
            //&& actionData?.singleActionDetails?.actionStatus !== "REASSIGNED") &&
            // actionData?.singleActionDetails?.actionStatus !== "REASSIGNED" &&
            actionData?.singleActionDetails?.isComplete === false &&
            actionData?.singleActionDetails?.isCancelled === false &&
            actionData?.singleActionDetails?.assignedUserId.toString() ===
              employeeData?.userData?._id?.toString() ? (
              <>
                {actionData.loading && !actionData.isSuccess ? (
                  <button className="add-btn Mom-btn mt-5" type="button">
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Processing...
                  </button>
                ) : (
                  <button
                    className="add-btn Mom-btn mt-5"
                    type="button"
                    onClick={() => {
                      handleRequestForReassignModal(props.actionId);
                    }}
                  >
                    <p>Request for Reassignment</p>
                  </button>
                )}
              </>
            ) : null}
          </>
        </div>
      </div>

      <RequestForReassignModal
        setIsModalOpen={setIsRequestForReassignModalOpen}
        isModalOpen={isRequestForReassignModalOpen}
        handleSubmit={handleRequestForReassign}
      />
      <IsCompleteModal
        setIsModalOpen={setIsCompleteModalOpen}
        isModalOpen={isCompleteModalOpen}
        handleSubmit={handleUpdateIsComplete}
      />
    </>
  );
};

export default ViewActionDetails;
