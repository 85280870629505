import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { newMeetingAsRescheduled, rescheduleMeeting } from "../../redux/actions/meetingActions/MeetingAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import LoaderButton from "../Common/LoaderButton";

const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
const RescheduleMeetingModal = (props) => {
  console.log(props);
  const meetingDatas = useSelector((state) => state.meeting);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const { meetingData, isFetchedMeetingList } = props;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    date: "",
    fromTime: "",
    toTime: "",
    remarks: "",
  });
  useEffect(() => {
    if (props.meetingData?.date) {
      var date = new Date(props.meetingData?.date);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }

    if (props.meetingData) {
      setFormData({
        ...formData,
        date: dateString ? dateString : null,
        toTime: props.meetingData?.actualToTime,
        fromTime: props.meetingData?.actualFromTime,
      });
    }

    if (isFetchedMeetingList === true) {
      setErrors({});
      setFormData({
        ...formData,
        date: "",
        fromTime: "",
        toTime: "",
        remarks: "",
      });
      props.setIsRescheduleModalOpen(false);
    }

    return () => {
      setErrors({});
      setFormData({
        ...formData,
        date: "",
        fromTime: "",
        toTime: "",
        remarks: "",
      });
    };
  }, [meetingData, isFetchedMeetingList]);

  const handleSubmit = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        ...formData,
        attendees: meetingData.attendees.map(
          ({
            rsvp,
            isAttended,
            empId,
            isEmployee,
            designation,
            companyName,
            isMeetingOrganiser,
            isAdmin,
            ...keepAttrs
          }) => keepAttrs
        ),
      };
      if (props?.isNewMeeting) {
        dispatch(newMeetingAsRescheduled(meetingData._id, payload, accessToken));
      } else {
        dispatch(rescheduleMeeting(meetingData._id, payload, accessToken));
      }
    }
  };

  const closeModal = () => {
    setErrors({});
    setFormData({
      ...formData,
      remarks: "",
    });
    props.setIsRescheduleModalOpen(false);
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};

    if (!data.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (data.date.trim()) {
      const currentDate = new Date();
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      differenceInDays =
        differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];
      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    if (!data.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }

    if (!formData.remarks.trim()) {
      errors.remarks = constantMessages.reasonRequired;
    } else if (!regularExpression.test(formData.remarks.trim())) {
      errors.remarks = constantMessages.titleRegexError;
    } else if (
      formData.remarks.trim().length < 3 ||
      formData.remarks.trim().length > 200
    ) {
      errors.remarks = constantMessages.largeStringLengthError;
    }

    return errors;
  };

  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    setErrors(errors);
  };

  const toDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.date.trim()) {
      errors.date = constantMessages.dateRequired;
    } else if (formData.date.trim()) {
      const currentDate = new Date();
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      differenceInDays =
        differenceInDays === -1 ? differenceInDays + 1 : differenceInDays;

      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateRemarks = () => {
    const errors = {};

    if (!formData.remarks.trim()) {
      errors.remarks = constantMessages.reasonRequired;
    } else if (!regularExpression.test(formData.remarks.trim())) {
      errors.remarks = constantMessages.titleRegexError;
    } else if (
      formData.remarks.trim().length < 3 ||
      formData.remarks.trim().length > 200
    ) {
      errors.remarks = constantMessages.largeStringLengthError;
    }

    setErrors(errors);
    return errors;
  };
  console.log("meetingData===============", meetingDatas);
  return (
    <Modal
      show={props.isRescheduleModalOpen}
      onHide={closeModal}
      backdrop="static"
    >
      <div className="modal-header">
        <h4 className="modal-title">Reschedule Meeting</h4>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={closeModal}
        ></button>
      </div>
      <div className="modal-body delete-txt">
        <div className="mb-0">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1 input-date">
                  Date
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  onBlur={dateFieldValidationCheck}
                />

                {errors.date && (
                  <span className="error-message">{errors.date}</span>
                )}
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1">
                  {" "}
                  From Time
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="time"
                  className="input-time"
                  name="fromTime"
                  value={formData.fromTime}
                  onChange={handleChange}
                  onBlur={fromDateFieldValidationCheck}
                />

                {errors.fromTime && (
                  <span className="error-message">{errors.fromTime}</span>
                )}
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="position-relative resp-input-margin-bottom">
                <label className="mb-1">
                  To Time
                  <span className="star-mark">*</span>
                </label>
                <input
                  type="time"
                  className="input-time2"
                  name="toTime"
                  value={formData.toTime}
                  onChange={handleChange}
                  onBlur={toDateFieldValidationCheck}
                />

                {errors.toTime && (
                  <span className="error-message">{errors.toTime}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12 delete-txt">
              <label className="mb-1 input-date">
                Reason
                <span className="star-mark">*</span>
              </label>

              <textarea
                cols="40"
                rows="4"
                id="remark"
                name="remarks"
                onBlur={validateRemarks}
                onChange={handleChange}
                value={formData.remarks}
                placeholder="Enter your reason here..."
                required
              ></textarea>
              <p className="success-message">
                {checkCharacterLeft(formData.remarks)} Characters left
              </p>
              {errors.remarks && (
                <span className="error-message">{errors.remarks}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        {!meetingDatas?.loading ? (
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Reschedule</p>
          </button>
        ) : (
          <LoaderButton />
        )}
      </div>
    </Modal>
  );
};

export default RescheduleMeetingModal;
