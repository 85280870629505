import React, { useState, useEffect } from "react";
import {
  getMeetingRoomList,
  listAllUnitForMeeting,
} from "../../redux/actions/meetingRoomAction/meetingRoomAction";
import { useSelector, useDispatch } from "react-redux";
import CommonStepper from "../Common/CommonStepper";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateIsCreateMeetingProcessed,
  updateMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import LoaderButton from "../Common/LoaderButton";
import AddAttendees from "./AddAttendees";
import AddAgendas from "./AddAgendas";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logOut } from "../../redux/actions/authActions/authAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import Select from "react-select";
const EditMeeting = () => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const organizationData = useSelector((state) => state.organization);
  console.log(
    organizationData?.organizationData?.hostingDetails?.zoomCredentials
  );
  const zoomCred =
    organizationData?.organizationData?.hostingDetails?.zoomCredentials;
  const [selectedRoomOption, setSelectedRoomOption] = useState([
    { label: "Select Room", value: "" },
  ]);
  const [selectedUnitOption, setSelectedUnitOption] = useState([
    { label: "Select Unit", value: "" },
  ]);

  const [step, setStep] = useState(0);

  const [errors, setErrors] = useState({});
  const [unitId, setUnitId] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    mode: "physical",
    location: "manual",
    date: "",
    link: "",
    fromTime: "10.55",
    toTime: "",
    roomId: "",
    locationData: "",
    linkType: null,
  });
  const location = useLocation();

  const stateData = location.state;
  console.log(meetingData?.singleMeetingDetails?.locationDetails);
  useEffect(() => {
    //  alert("check");
    if (stateData?.isMeetingDataUpdate) {
      document.title = "Edit Meeting: MinutesVault";
    }

    if (meetingData?.singleMeetingDetails?.date) {
      var date = new Date(meetingData?.singleMeetingDetails?.date);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }

    setStep(meetingData.step + 1);
    if (meetingData.singleMeetingDetails && stateData.isMeetingDataUpdate) {
      setFormData({
        ...formData,
        title: meetingData.singleMeetingDetails.title,
        mode: meetingData.singleMeetingDetails.mode.toLowerCase(),
        location: meetingData.singleMeetingDetails.locationDetails.isMeetingRoom
          ? "meetingroom"
          : "manual",

        link: meetingData.singleMeetingDetails.link
          ? meetingData.singleMeetingDetails.link
          : "",
        toTime: meetingData?.singleMeetingDetails?.actualToTime,
        fromTime: meetingData?.singleMeetingDetails?.actualFromTime,
        roomId: meetingData.singleMeetingDetails.locationDetails.roomId,
        locationData: meetingData.singleMeetingDetails.locationDetails.location
          ? meetingData.singleMeetingDetails.locationDetails.location
          : "",
        date: dateString ? dateString : null,
        linkType: meetingData?.singleMeetingDetails?.linkType?meetingData?.singleMeetingDetails?.linkType:
        meetingData?.singleMeetingDetails?.hostDetails?.hostType? meetingData?.singleMeetingDetails?.hostDetails?.hostType:null
      });
      if (
        (meetingData.singleMeetingDetails.locationDetails.isMeetingRoom &&
          meetingData.step === 1) ||
        3
      ) {
        const unitPayload = {
          organizationId: userData.organizationId,
        };
        dispatch(listAllUnitForMeeting(unitPayload, accessToken));
        if (meetingData?.singleMeetingDetails?.unitDetail) {
          const payload = {
            unitId: meetingData?.singleMeetingDetails?.unitDetail?._id,
            organizationId: userData?.organizationId,
          };

          dispatch(getMeetingRoomList(payload, accessToken));
        }
      }

      if (meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom) {
        setSelectedRoomOption({
          value: meetingData.singleMeetingDetails?.roomDetail[0]._id,
          label: meetingData.singleMeetingDetails?.roomDetail[0].title,
          name: "roomId",
        });
        if (meetingData?.singleMeetingDetails?.unitDetail) {
          setSelectedUnitOption({
            value: meetingData.singleMeetingDetails?.unitDetail._id,
            label: meetingData.singleMeetingDetails?.unitDetail.name,
            name: "unitId",
          });
          setUnitId(meetingData.singleMeetingDetails?.unitDetail._id);
        }
      }
      if (
        meetingData?.singleMeetingDetails?.locationDetails?.isMeetingRoom ===
        false
      ) {
        setSelectedRoomOption([{ label: "*Select Rooms", value: "" }]);
        setSelectedUnitOption([{ label: "*Select Units", value: "" }]);
      }
    }
    return () => {
      setFormData({
        ...formData,
        title: "",
        mode: "physical",
        location: "manual",
        date: "",
        link: "",
        fromTime: "",
        toTime: "",
        roomId: "",
        locationData: "",
      });
    };
  }, [
    meetingData.step,
    meetingData.singleMeetingDetails,
    meetingData.isSuccess,
  ]);
console.log(meetingData.singleMeetingDetails)
console.log(formData.linkType)
  const submitMeetingDetails = (e) => {
   // alert("clicked")
    e.preventDefault();
    let sendNotification = false;
    let reScheduled = false;
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      let locationDetails = {};
      if (formData.location === "meetingroom") {
        locationDetails["isMeetingRoom"] = true;
        locationDetails["roomId"] = formData.roomId;
      } else {
        locationDetails["isMeetingRoom"] = false;
        locationDetails["location"] = formData.locationData;
      }

      if (meetingData.singleMeetingDetails || stateData.isMeetingDataUpdate) {
        const meetingId = meetingData?.singleMeetingDetails?._id;
        const payload = {
          date: new Date(formData.date),
          locationDetails,
          organizationId: userData.organizationId,
          mode: formData.mode.toUpperCase(),
          fromTime: formData.fromTime,
          toTime: formData.toTime,
          title: formData.title,
          link: formData.link,
          step: 1,
          sendNotification,
          reScheduled,
          isUpdate:
            stateData.isMeetingDataUpdate &&
              meetingData.singleMeetingDetails.step === 3
              ? true
              : false,

          linkType: formData.linkType ? formData.linkType : undefined,
        };

        dispatch(
          updateMeetingDetails(
            meetingId,
            payload,
            accessToken,
            "updateMeeting",
            false
          )
        );
      }

      if (meetingData.isSuccess) {
        setStep(2);
      }
    } else {
      console.log(newErrors)
      alert("error")
    }
  };

  const handleRoomChange = (e) => {
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});

    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedRoomOption(e);
  };

  const handleUnitChange = (e) => {
    dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});

    const { name, value } = e;
    setUnitId(value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedUnitOption(e);
    const payload = {
      organizationId: userData.organizationId,
      unitId: value,
    };

    dispatch(getMeetingRoomList(payload, accessToken));

    setFormData({
      ...formData,
      roomId: "",
    });
    setSelectedRoomOption([{ label: "Select Room", value: "" }]);
  };

  const handleChange = (e) => {
    dispatch(updateIsCreateMeetingProcessed(false));

    setErrors({});

    const { name, value } = e.target;

    if (name === "locationData") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,

          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,

        [name]: value,
      });
    }

    if (value === "meetingroom") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(listAllUnitForMeeting(payload, accessToken));
    }

    // if (value === "manual") {
    //   setUnitId(null);
    //   setSelectedRoomOption([{ label: "Select Room(s)", value: "" }]);
    //   setSelectedUnitOption([{ label: "Select Unit(s)", value: "" }]);
    // }
    if (!meetingData?.singleMeetingDetails?.unitDetail) {
      setUnitId(null);
      setSelectedRoomOption([{ label: "*Select Rooms", value: "" }]);
      setSelectedUnitOption([{ label: "*Select Units", value: "" }]);
    }
    if(name==="mode" && value==="physical"){
      setFormData({
        ...formData,
        linkType: "MANUAL",
        link:"",
        mode:value
      });
    }
    if(name==="mode" && value==="virtual"){
      setFormData({
        ...formData,
        linkType: null,
        link:"",
        mode:value
      });
    }
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};
    if (!data.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(data.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (data.title.trim().length < 5 || data.title.trim().length > 100) {
      errors.title = constantMessages.stringLengthError;
    }

    if (data.locationData.trim()) {
      if (!regularExpression.test(data.locationData)) {
        errors.locationData = constantMessages.titleRegexError;
      } else if (
        data.locationData.trim().length < 5 ||
        data.title.trim().length > 100
      ) {
        errors.locationData = constantMessages.stringLengthError;
      }
    }

    if (
      !data.date.trim() &&
      meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    ) {
      errors.date = constantMessages.dateRequired;
    } else if (
      data.date.trim() &&
      meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    ) {
      const currentDate = new Date();
      const inputDate = new Date(data.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }

    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    if (!data.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }

    if (data?.location === "meetingroom") {
      if (!unitId) {
        errors.unitId = constantMessages.unitRequired;
      }

      if (!data?.roomId?.trim()) {
        errors.roomId = constantMessages.roomRequired;
      }
    } else {
      if (!data?.locationData?.trim()) {
        errors.locationData = constantMessages.locationRequired;
      }
    }
    // if (formData?.mode?.trim() === "virtual") {
    //   if (!formData?.link?.trim()) {
    //     errors.link = constantMessages.linkRequired;
    //   }
    // }

    if (
      formData?.mode?.trim() === "virtual" &&
      formData?.linkType === "MANUAL"
    ) {
      if (!formData?.link?.trim()) {
        errors.link = constantMessages.linkRequired;
      }
    }

    if (formData?.mode?.trim() === "virtual") {
      if (!formData?.linkType) {
        errors.linkType = "Please add link type";
      }
    }

    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (formData?.link?.trim()) {
      if (!regexp.test(formData?.link?.trim())) {
        errors.link = constantMessages.invalidLink;
      }
    }
    setErrors(errors);
    return errors;
  };

  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    setErrors(errors);
  };

  const locationDetailsFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.locationData.trim()) {
      errors.locationData = constantMessages.locationRequired;
      setErrors(errors);
    } else if (!regularExpression.test(formData.locationData)) {
      errors.locationData = constantMessages.titleRegexError;
    } else if (
      formData.locationData.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.locationData = constantMessages.stringLengthError;
    }
  };
  const toDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (
      !formData.date.trim() &&
      meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    ) {
      errors.date = constantMessages.dateRequired;
    } else if (
      formData.date.trim() &&
      meetingData?.singleMeetingDetails?.meetingStatus?.status === "draft"
    ) {
      const currentDate = new Date();
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
  };

  const titleFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.title.trim()) {
      errors.title = constantMessages.titleRequired;
      setErrors(errors);
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }
  };

  const meetinRoomFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.roomId) {
      errors.roomId = constantMessages.roomRequired;
      setErrors(errors);
    }
  };

  const urlFieldValidationCheck = (e) => {
    const errors = {};
    var regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (formData.link.trim()) {
      if (!regexp.test(formData.link.trim())) {
        errors.link = constantMessages.invalidLink;
        setErrors(errors);
      }
    }
  };

  if (meetingRoomData?.meetingRoomList.length !== 0) {
    var roomOptions = meetingRoomData?.meetingRoomList?.map((room) => {
      const returnData = {
        value: room._id,
        label: room.title,
        name: "roomId",
      };

      return returnData;
    });
  }

  if (meetingRoomData?.unitList?.length !== 0) {
    var unitOptions = meetingRoomData?.unitList?.map((unit) => {
      const returnData = {
        value: unit._id,
        label: unit.name,
        name: "unitId",
      };

      return returnData;
    });
  }

  const stepCount = meetingData.isNewMeetingPage
    ? 1
    : meetingData?.singleMeetingDetails?.step;

  return (
    <div className="mt-2 details-form add-meetings">
      <CommonStepper step={stepCount} />
      <br></br>

      {meetingData.step + 1 == 1 ||
        meetingData.isNewMeetingPage === true ||
        (stateData.isMeetingDataUpdate && meetingData.step === 3) ? (
        <form className="mt-0 p-0 details-form" onSubmit={submitMeetingDetails}>
          <div className="inner-detail-form">
            <div className="mb-3">
              <label className="mb-1" for="title">
                Title
              </label>
              <span className="star-mark">*</span>

              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter Meeting Title"
                onBlur={titleFieldValidationCheck}
              />
              {errors.title && (
                <span className="error-message">{errors.title}</span>
              )}
            </div>

            <div className="mb-3">
              <label className="mb-1">Meeting Mode</label>
              <span className="star-mark">*</span>
              <div className="w-100 resp-grid">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="mode"
                    value="virtual"
                    onChange={handleChange}
                    checked={formData.mode === "virtual"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Virtual Meeting
                  </label>
                </div>
                <div className="form-check form-check-inline padding-left-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="mode"
                      value="physical"
                      onChange={handleChange}
                      checked={formData.mode === "physical"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Physical Meeting
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {formData.mode === "virtual" ? (
              <div className="mb-3">
                <label className="mb-1">Select Link Type</label>
                <span className="star-mark">*</span>
                <select
                  className="fltr-opt"
                  aria-placeholder="Select Link Type"
                  onChange={handleChange}
                  name="linkType"
                  value={formData.linkType}
                >
                  <option value="null">Select Link Type</option>
                  <option value="MANUAL">My Link</option>
                  {zoomCred?.isActive === true &&
                  zoomCred?.clientId &&
                  zoomCred?.clientId !== "" &&
                  zoomCred?.accountId &&
                  zoomCred?.accountId !== "" &&
                  zoomCred?.secretToken &&
                  zoomCred?.secretToken !== "" ? (
                    <option value="ZOOM"> Zoom</option>
                  ) : null}
                </select>
                {errors.linkType && (
                  <span className="error-message">{errors.linkType}</span>
                )}
              </div>
            ) : null}
            {formData.linkType === "MANUAL" || formData.mode === "physical" ? (
              <div className="mb-3">
                <label className="mb-1">Meeting Link</label>
                {formData.linkType === "MANUAL" &&
                formData.mode === "virtual" ? (
                  <span className="star-mark">*</span>
                ) : null}
                <input
                  type="text"
                  placeholder="Enter Meeting Link"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  onBlur={urlFieldValidationCheck}
                  autoComplete="off"
                />
                {errors.link && (
                  <span className="error-message">{errors.link}</span>
                )}
              </div>
            ) : null}

            {/* <div className="mb-3">
              <label className="mb-1">Meeting Link</label>
              {formData.mode === "virtual" ? (
                <span className="star-mark">*</span>
              ) : null}
              <input
                type="text"
                placeholder="Enter Meeting Link"
                name="link"
                value={formData.link}
                onChange={handleChange}
                onBlur={urlFieldValidationCheck}
                autoComplete="off"
              />
              {errors.link && (
                <span className="error-message">{errors.link}</span>
              )}
            </div> */}
            <div className="mb-3">
              <label className="mb-1" for="location">
                Location
              </label>
              <span className="star-mark">*</span>
              <div className="w-100 resp-grid mb-2">
                <div className="form-check form-check-inline">
                  { }
                  <input
                    id="flexRadioDefault1"
                    className="form-check-input"
                    type="radio"
                    name="location"
                    value="manual"
                    onChange={handleChange}
                    checked={formData.location === "manual"}
                  />
                  <label className="form-check-label" for="locationtype">
                    Enter Manually
                  </label>
                </div>
                <div className="form-check form-check-inline padding-left-0">
                  <div className="form-check">
                    <input
                      id="flexRadioDefault1"
                      className="form-check-input"
                      type="radio"
                      name="location"
                      value="meetingroom"
                      onChange={handleChange}
                      checked={formData.location === "meetingroom"}
                      onBlur={meetinRoomFieldValidationCheck}
                    />
                    <label className="form-check-label" for="locationtype">
                      Select A Meeting Room
                    </label>
                  </div>
                </div>
              </div>

              {formData.location === "meetingroom" ? (
                <div className="mb-3">
                  <label className="mb-1">Select Unit</label>
                  <span className="star-mark">*</span>
                  <Select
                    className="fltr-opt"
                    defaultValue={selectedUnitOption}
                    name="unitId"
                    options={unitOptions}
                    onChange={handleUnitChange}
                    value={selectedUnitOption}
                  />
                  {errors.unitId && (
                    <span className="error-message">{errors.unitId}</span>
                  )}
                </div>
              ) : null}

              {formData.location !== "meetingroom" ? (
                <>
                  <textarea
                    className="mt-1"
                    placeholder="Enter Location"
                    id=""
                    cols="56"
                    rows="3"
                    onChange={handleChange}
                    name="locationData"
                    value={formData.locationData}
                    onBlur={locationDetailsFieldValidationCheck}
                  ></textarea>

                  <p className="success-message">
                    {checkCharacterLeft(formData.locationData)} Characters left
                  </p>
                  {errors.locationData && (
                    <span className="error-message">{errors.locationData}</span>
                  )}
                </>
              ) : (
                <>
                  {unitId ? (
                    <div className="mb-3">
                      <label className="mb-1">Select Room</label>
                      <span className="star-mark">*</span>
                      <Select
                        className="fltr-opt"
                        defaultValue={selectedRoomOption}
                        name="roomId"
                        options={roomOptions}
                        onChange={handleRoomChange}
                        value={selectedRoomOption}
                      />

                      {errors.roomId && (
                        <span className="error-message">{errors.roomId}</span>
                      )}
                    </div>
                  ) : null}
                </>
              )}
            </div>

            <div className="mb-3">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1 input-date">Date</label>
                    <span className="star-mark">*</span>
                    <input
                      disabled={
                        meetingData?.singleMeetingDetails?.meetingStatus
                          ?.status === "draft"
                          ? false
                          : true
                      }
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={dateFieldValidationCheck}
                    />

                    {errors.date && (
                      <span className="error-message">{errors.date}</span>
                    )}
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1"> From Time</label>
                    <span className="star-mark">*</span>
                    <input
                      disabled={
                        meetingData?.singleMeetingDetails?.meetingStatus
                          ?.status === "draft"
                          ? false
                          : true
                      }
                      type="time"
                      className="input-time"
                      name="fromTime"
                      value={formData?.fromTime?.toString()}
                      onChange={handleChange}
                      onBlur={fromDateFieldValidationCheck}
                    />

                    {errors.fromTime && (
                      <span className="error-message">{errors.fromTime}</span>
                    )}
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="position-relative resp-input-margin-bottom">
                    <label className="mb-1">To Time</label>
                    <span className="star-mark">*</span>
                    <input
                      disabled={
                        meetingData?.singleMeetingDetails?.meetingStatus
                          ?.status === "draft"
                          ? false
                          : true
                      }
                      type="time"
                      className="input-time2"
                      name="toTime"
                      value={formData.toTime}
                      onChange={handleChange}
                      onBlur={toDateFieldValidationCheck}
                    />

                    {errors.toTime && (
                      <span className="error-message">{errors.toTime}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="button-outer">
              {!meetingData.loading && stateData?.isMeetingDataUpdate ? (
                <button className="create-meeting-button Mom-btn" type="submit">
                  <p>Update</p>
                </button>
              ) : (
                <LoaderButton />
              )}

              {!meetingData.loading && stateData?.isMeetingDataUpdate ? (
                <button
                  className="add-minutes Mom-btn"
                  onClick={(e) => dispatch(updateStep(1))}
                >
                  <p>Next</p>
                </button>
              ) : !meetingData.loading && !stateData?.isMeetingDataUpdate ? (
                <button className="add-minutes Mom-btn">
                  <p>Next</p>
                </button>
              ) : meetingData.loading && !stateData?.isMeetingDataUpdate ? (
                <LoaderButton />
              ) : null}
            </div>
          </div>
        </form>
      ) : meetingData.step + 1 === 2 && !meetingData.isNewMeetingPage ? (
        <>
          <AddAttendees />
        </>
      ) : meetingData.step + 1 === 3 && !meetingData.isNewMeetingPage ? (
        <>
          <AddAgendas />
        </>
      ) : null}

    </div>
  );
};

export default EditMeeting;
