import {
  MAKE_REQUEST,
  FAIL_REQUEST,
  GET_ACTION_LIST,
  UPDATE_FETCH_ACTION_LIST_STATUS,
  SET_REQUEST_FOR_REASSIGN,
  SET_SINGLE_ACTION_DETAILS,
  SET_UPDATE_IS_COMPLETE_RESPONSE,
  SET_ACTION_ACTIVITIES_DETAILS,
  UPDATE_ISSUCCESS,
  SET_CANCEL_ACTION_RESPONSE,
  SET_ACTION_LIST_FOR_CHART
} from "../actions/actionAction/actionTypes";

const initialObject = {
  loading: false,
  singleActionDetails: null,
  message: "",
  totalCount: 0,
  isSuccess: false,
  actionList: [],
  isFetchedActionList: false,
  statusData: [
    "PENDING",
    "REQUESTFORREASSIGN",
    "REASSIGNED",
    "COMPLETED",
    "REOPENED",
    "APPROVED",
    "CANCELLED",
    //"DELAYED",
    //"NOTDELAYED",
  ],
  actionActivitiesDetails: [],
  totalActionList:[]
};

export const actionReducer = (state = initialObject, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_ISSUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case GET_ACTION_LIST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        actionList: action.payload.data?.actionDatas
          ? action.payload.data?.actionDatas
          : [],
        totalCount: action.payload.data?.totalCount,
        isSuccess: action.payload.success,
        isFetchedActionList: false,
      };
    case UPDATE_FETCH_ACTION_LIST_STATUS:
      return {
        ...state,
        isFetchedActionList: action.payload,
      };

    case SET_REQUEST_FOR_REASSIGN:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case SET_SINGLE_ACTION_DETAILS:
      return {
        ...state,
        loading: false,

        singleActionDetails: action.payload.data,
      };

    case SET_UPDATE_IS_COMPLETE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case SET_ACTION_ACTIVITIES_DETAILS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        actionActivitiesDetails: action.payload?.data
          ? action.payload?.data
          : [],
      };
    case SET_CANCEL_ACTION_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
      case SET_ACTION_LIST_FOR_CHART:
        return {
          ...state,
          loading: false,
          message: action.payload.message,
          isSuccess: action.payload.success,
          totalActionList:action.payload.data
        };

    default:
      return state;
  }
};
