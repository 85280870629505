import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { customName } from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import {
  getAgendaWithMinutesDetails,
  giveMomWritePermission,
} from "../../redux/actions/meetingActions/MeetingAction";

const AttendeesModal = (props) => {
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const meetingDetails = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const { attendees, meetingData, attendeeType } = props;
  const [attendeesArray, setAttendeesArray] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fromTime: "",
    toTime: "",
  });

  useEffect(() => {
    setAttendeesArray(attendees);
    setFormData({
      ...formData,
      fromTime: meetingData?.actualFromTime,
      toTime: meetingData?.actualToTime,
    });
    if (meetingDetails.isAttendanceStatus && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingData._id, accessToken));
      props.setIsModalOpen(false);
    }
  }, [
    props.isAttendace,
    props.IsModalOpen,
    meetingDetails.isAttendanceStatus,
    meetingDetails.loading,
  ]);

  const checkMomWritePermissionHandler = (e, attendeeId) => {
    const { checked } = e.target;

    if (attendeeId) {
      const modifiedAttendees = attendeesArray.map((obj) => {
        if (obj._id === attendeeId) {
          return { ...obj, canWriteMOM: checked };
        }
        return obj;
      });

      setAttendeesArray(modifiedAttendees);
    }
  };

  const submitMomWritePermission = () => {
    const payload = {
      attendees: attendeesArray.map(
        ({
          name,
          email,
          isEmployee,
          empId,
          designation,
          companyName,
          isMeetingOrganiser,
          isAdmin,
          ...keepAttrs
        }) => keepAttrs
      ),
    };
    dispatch(giveMomWritePermission(meetingData?._id, payload, accessToken));
  };

  if (attendeeType === "guests") {
    const attendeesArrayFilter = attendeesArray.filter(
      (attendeeType) => attendeeType.isEmployee === false
    );
    setAttendeesArray(attendeesArrayFilter);
  }

  if (attendeeType === "attendees") {
    const attendeesArrayFilter = attendeesArray.filter(
      (attendeeType) => attendeeType.isEmployee === true
    );
    setAttendeesArray(attendeesArrayFilter);
  }

  return (
    <>
      <Modal
        show={props.IsModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">Attendees</h4>
          </div>

          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsModalOpen(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="modal-height">
          <div className="attendees-popup">
            {attendeesArray &&
              attendeesArray.map((attendee, index) => {
                return (
                  <div className="attendee-content" key={index}>
                    {props.loginUserData?.userData?.isMeetingOrganiser ||
                    props.loginUserData?.userData?.isAdmin ? (
                      <div className="check-attendee">
                        {props.isShow &&
                        (meetingData?.meetingStatus?.status === "scheduled" ||
                          meetingData?.meetingStatus?.status ===
                            "rescheduled" ||
                          meetingData?.meetingStatus?.status === "draft") ? (
                          <input
                            type="checkbox"
                            name="updateAttendance"
                            id=""
                            className="form-check-input"
                            onChange={(e) =>
                              checkMomWritePermissionHandler(e, attendee._id)
                            }
                            disabled={
                              (
                                attendee?.isAdmin === true ||
                                attendee?.isMeetingOrganiser === true)||
                              attendee?._id?.toString() ===
                                props?.meetingData?.createdById?.toString() ||
                              (attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                                employeeData.userData.isAdmin === true) ||
                              (attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                                employeeData.userData.isMeetingOrganiser ===
                                  true)
                                ? true
                                : false
                            }
                            checked={
                              (
                                attendee?.isAdmin === true ||
                                attendee?.isMeetingOrganiser === true)||
                              (attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                                employeeData.userData.isAdmin === true) ||
                              (attendee?._id?.toString() ===
                                employeeData.userData._id.toString() &&
                                employeeData.userData.isMeetingOrganiser ===
                                  true) ||
                                  attendee?._id?.toString() ===
                                  props?.meetingData?.createdById?.toString() 
                                ? true
                                : attendee.canWriteMOM
                            }
                          />
                        ) : null}

                        <div className="attendee1 attendee-list sl">
                          {" "}
                          {customName(attendee.name)}
                        </div>
                      </div>
                    ) : (
                      <div className="attendee-content" key={index}>
                        <div className="attendee1 attendee-list sl">
                          {" "}
                          {customName(attendee.name)}
                        </div>
                      </div>
                    )}

                    <div className="action-cmnt-text">
                      <p className="detail-name word-break">
                        {" "}
                        {attendee?._id?.toString() ===
                        props?.meetingData?.createdById?.toString()
                          ? attendee.name + " (Organiser)"
                          : attendee?._id?.toString() ===
                              employeeData.userData._id.toString() &&
                            employeeData.userData.isAdmin === true
                          ? attendee.name + " (Admin)"
                          : attendee.name}
                      </p>

                      {attendee?._id?.toString() ===
                        props?.meetingData?.createdById?.toString() &&
                      employeeData.userData.isAdmin === true ? (
                        <p className="name-undertext detail-date-time comment-text word-break">
                          {attendee.email}
                        </p>
                      ) : (
                        <>
                          {attendeeType && attendeeType == "attendees" ? (
                            <p className="name-undertext detail-date-time comment-text word-break">
                              {attendee.email}{" "}
                              {attendee.empId
                                ? " (" + attendee.empId + ") "
                                : ""}
                            </p>
                          ) : attendeeType && attendeeType == "guests" ? (
                            <p className="name-undertext detail-date-time comment-text word-break">
                              {attendee.email}{" "}
                              {attendee.empId
                                ? " (" + attendee.empId + ") "
                                : ""}
                            </p>
                          ) : (
                            <>
                              <p className="name-undertext detail-date-time  comment-text word-break">
                                {attendee.email}{" "}
                                {attendee.empId
                                  ? " (" + attendee.empId + ") "
                                  : " (Guest) "}
                              </p>
                              <div className="des-comp">
                              {attendee?.designation ? (
                                <span className="name-undertext detail-date-time comment-text word-break me-2 text-des-comp">
                                  {attendee.designation}
                                </span>
                              ) : null}
                              {attendee?.companyName ? (
                                <span className="name-undertext detail-date-time comment-text word-break text-des-comp">
                                  {attendee.companyName}
                                </span>
                              ) : null}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {props.loginUserData?.userData?.isMeetingOrganiser &&
                      props.isShow &&
                      (attendee._id.toString() ===
                      employeeData.userData._id.toString()
                        ? true
                        : attendee.canWriteMOM) &&
                      (meetingData?.meetingStatus?.status === "scheduled" ||
                        meetingData?.meetingStatus?.status === "rescheduled" ||
                        meetingData?.meetingStatus?.status === "draft") ? (
                        <span className="permission">
                          With MOM write permission
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>

        <div className="">
          {!props.isAttendace &&
          (props.loginUserData?.userData?.isMeetingOrganiser ||
            props.loginUserData?.userData?.isAdmin) &&
          (meetingData?.meetingStatus?.status === "scheduled" ||
            meetingData?.meetingStatus?.status === "rescheduled" ||
            meetingData?.meetingStatus?.status === "draft") ? (
            <Modal.Footer>
              <button
                type="button"
                className="Mom-btn btn btn-secondary bg-primary border-primary"
                onClick={submitMomWritePermission}
              >
                <p>Set MOM Write Permission</p>
              </button>
            </Modal.Footer>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default AttendeesModal;
