import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useSelector } from "react-redux";
import LoaderButton from "../Common/LoaderButton";
const WarningModal = (props) => {
 

  const handleSubmit = () => {
    props.handleSubmit();
  };

  return (
    <>
      <Modal
        show={props.isModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          {props?.loading ? (
            <LoaderButton />
          ) : (
            <Button variant="" className="add-minutes Mom-btn" onClick={handleSubmit}>
              <p>Yes</p>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WarningModal;
