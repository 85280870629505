import React from "react";
import NoDataFound from "../Common/NoDataFound";
import { Alert } from "bootstrap";
import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";

const AttendeesDetails = (props) => {
  const meetingData = useSelector((state) => state.meeting);

  const { attendees } = props.attendeesData;
  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>Attendees</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {attendees.length > 0 ? (
          <form className=" details-form details-form-right responsive-table tbl-2-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">RSVP</th>
                </tr>
              </thead>

              <tbody>
                {attendees.length !== 0 ? (
                  <>
                    {attendees.map((attendee, index) => {
                      return (
                        <tr key={index}>
                          <td
                            data-label="Sl. No."
                            className="blue-bold-text"
                            scope="row"
                          >
                            {index + 1}
                          </td>
                          <td data-label="Attendees">
                            <div className="attendees responsive-right">
                              <div className="attendee-list">
                                {customName(attendee.name)}
                              </div>

                              <div className="action-cmnt-text">
                                <p className="detail-name word-break">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee.name
                                  )}
                                </p>
                                <p className="name-undertext detail-date-time  comment-text word-break">
                                  {attendee.email}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td data-label="RSVP">
                            {convertFirstLetterToCapital(attendee.rsvp)}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : attendees.length === 0 && !meetingData.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendeesDetails;
