import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  downloadMOM,
  getAgendaWithMinutesDetails,
  setMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import ViewMinutes from "./ViewMinutes";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import ViewParentActions from "./ViewParentActions";
import MOMAcceptanceDetails from "./MOMAcceptanceDetails";
import { logOut } from "../../redux/actions/authActions/authAction";

const ViewMinutePage = (props) => {
  let { meetingId } = useParams();
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken");
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const employeeData = useSelector((state) => state.user);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);

  useEffect(() => {
    document.title = "View Minutes: MinutesVault";
    if (meetingId && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
    }
    return () => {
     // dispatch(setMeetingDetails(null))
    };
  }, []);
  console.log(meetingData?.meetingDetails?._id)
  return (
    <>
      <Header />
      <Sidebar />
      {meetingData?.meetingDetails !== null ? (
        <div className="main-content">
          <div className="row">
            {/* {meetingData?.meetingDetails!==null?( */}
            <>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                <ViewMinutes meetingId={meetingId} />
                {meetingData?.meetingDetails?.parentMeetingId ? (
                  <form className="mt-4">
                    <ViewParentActions
                      parentMeetingId={
                        meetingData?.meetingDetails?.parentMeetingId
                      }
                      childMeetingId={meetingData?.meetingDetails?._id}
                      isHideOptions={true}
                    />
                  </form>
                ) : null}
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                <div className="meeting-header-text">
                  {meetingId ? <h4>&nbsp;</h4> : <h4>Meeting Preview</h4>}

                  {meetingId &&
                  meetingData?.meetingDetails?.momGenerationDetails?.length !==
                    0 ? (
                    <div className="">
                      <div className="row">
                        <div className="col">
                          <div className="d-inline-block">
                            {meetingData?.loading ? (
                              <LoaderButton />
                            ) : (
                              <button
                                className="Mom-btn"
                                onClick={() => {
                                  dispatch(downloadMOM(meetingId, accessToken));
                                }}
                              >
                                <p>Download MOM</p>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <form className="mt-2 details-form details-form-right">
                  <MeetingAttendance />
                </form>
                {minuteData ? (
                  <form className="mt-4 details-form details-form-right">
                    <MOMAcceptanceDetails meetingId={meetingId} />
                  </form>
                ) : null}
                {employeeData.userData.isMeetingOrganiser ||
                employeeData.userData.isAdmin ? (
                  <form className="mt-4 details-form details-form-right">
                    <MeetingActivities meetingId={meetingId} />
                  </form>
                ) : null}
              </div>
            </>
          </div>
        </div>
      ) : meetingData?.meetingDetails === null &&
        !meetingData.loading &&
        !meetingData.isSuccess ? (
        <div className="main-content">
          <div className="row">
            <NoDataFound />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default ViewMinutePage;
