import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { logInByGmail } from "../../redux/actions/authActions/authAction";
import { toast } from "react-toastify";
const GoogleAuth = () => {
  const dispatch = useDispatch();
  const clientId =process.env.REACT_APP_CLIENT_ID;
  const handleLogin = async (googleData) => {
    console.log(googleData);
    dispatch(logInByGmail(googleData));
  };

  const handleError = async (err) => {
    console.log(err);
    toast.error(err.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
  };

  return (
    <div style={{ borderRadius: "15px" }}>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          theme="filled_blue"
          size="medium"
          //    onSuccess={credentialResponse => {
          //      console.log(credentialResponse);
          //    }}
        //   onError={() => {
        //     console.log("Login Failed");
        //   }}
          buttonText="Log in with Google"
          onSuccess={(credentialResponse) => handleLogin(credentialResponse)}
          //   onSuccess={credentialResponse => {
          //     console.log(credentialResponse);
          //   }}
          onFailure={(err) => handleError(err)}
          cookiePolicy={"single_host_origin"}
        />
      </GoogleOAuthProvider>
    </div>
  );
};
export default GoogleAuth;
